export function HotelLogo({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 2.47 3575.37 1011.92">
      <path
        fill="#BC9B6A"
        d="M2709.363 138.417l-114.052 291.31c-3.513 7.62-5.607 15.817-6.179 24.188 0 12.359 9.181 20.833 28.601 28.425h-147.244c21.768-6.223 39.232-22.506 46.963-43.785l191.912-433.258 192.088 433.258c7.731 21.279 25.195 37.561 46.963 43.785h-147.068c19.421-7.592 28.425-16.066 28.425-28.425-0.477-8.407-2.638-16.632-6.356-24.188l-114.052-291.31z"
      />
      <path
        fill="#BC9B6A"
        d="M263.062 52.966c-108.403 0-178.317 75.034-178.317 189.617s67.090 191.735 176.552 191.735c58.454 1.178 114.124-24.927 150.599-70.621l-8.298 96.044c-71.503 21.186-103.989 27.542-154.659 27.542-154.306 0.177-248.938-107.697-248.938-241.17 0-138.946 104.166-239.404 248.408-239.404 40.949 1.467 81.791 5.121 122.35 10.946 10.894 0.524 21.072-5.43 25.953-15.183v111.051c0 0-32.839-60.557-133.65-60.557z"
      />
      <path
        fill="#BC9B6A"
        d="M835.619 6.532c-137.534 0-231.812 96.574-231.812 236.756s100.634 244.171 231.812 244.171c131.178 0 230.93-105.931 230.93-244.171s-94.455-236.756-230.93-236.756zM832.618 435.2c-85.098 0-150.952-83.862-150.952-192.794s66.56-186.615 156.601-186.615c90.041 0 151.305 74.152 151.305 188.734 0 111.757-65.324 190.676-156.954 190.676z"
      />
      <path
        fill="#BC9B6A"
        d="M1627.63 343.746v-249.821c0-49.788-4.767-58.086-40.254-69.738h137.357c-31.956 15.183-34.251 20.833-34.251 70.621v396.712l-353.103-335.448v258.825c0 45.197 5.65 52.966 43.785 67.443h-147.597c28.248-10.24 39.371-22.246 39.371-66.737v-409.423l354.692 337.567z"
      />
      <path
        fill="#BC9B6A"
        d="M2245.738 137.181c3.083 52.191-31.884 99.013-82.803 110.874l88.276 108.050c39.901 49.964 80.861 100.988 120.408 126.058h-99.222l-194.383-239.934c47.139-9.887 90.748-38.135 90.748-92.513 0-43.079-35.31-78.036-86.51-78.036-14.783-0.099-29.511 1.802-43.785 5.65v307.553c0 32.132 0 84.921 42.902 97.28h-150.246c44.668-12.359 41.49-65.854 41.49-97.28v-261.297c0-31.956 5.473-85.981-35.31-97.81h163.663c60.91 0 144.596 22.775 144.596 111.581z"
      />
      <path
        fill="#BC9B6A"
        d="M3513.379 254.234c0-154.483-115.465-228.634-265.887-228.634h-172.314c39.018 13.771 40.783 23.658 40.783 67.972v322.030c0 44.668-10.063 56.32-40.783 66.56h147.597c178.847 0 290.604-81.214 290.604-227.928zM3253.142 428.844h-34.074c-28.425 0-38.135-11.123-38.135-43.785v-314.439h65.854c114.582 0 184.673 75.741 184.673 185.379s-73.622 173.021-178.317 173.021z"
      />
      <path
        fill="#BC9B6A"
        d="M3548.69 27.366c3.38-0.604 6.807-0.899 10.24-0.883 4.17-0.41 8.339 0.791 11.652 3.354 2.205 1.971 3.432 4.812 3.354 7.768 0.033 4.819-3.14 9.074-7.768 10.417v0c3.364 1.465 5.664 4.644 6.003 8.298 0.745 3.79 1.808 7.511 3.178 11.123h-5.473c-1.265-3.12-2.154-6.38-2.648-9.71-1.236-5.65-3.531-7.768-8.298-7.945h-4.943v17.655h-5.297v-40.077zM3553.986 45.903h5.473c5.65 0 9.181-3.001 9.181-7.768s-3.884-7.415-9.357-7.592c-1.761-0.184-3.536-0.184-5.297 0v15.36z"
      />
      <path
        fill="#BC9B6A"
        d="M309.495 858.041c0-49.258 0-58.262 0-68.679s-3.354-16.066-13.948-17.655c-4.19-0.788-8.449-1.143-12.712-1.059-3.531 0-5.473 0-5.473-2.825s2.295-3.001 7.239-3.001c17.655 0 37.429 1.059 43.961 1.059 15.89 0 28.425-1.059 39.548-1.059 58.615 0 68.855 31.426 68.855 48.552-1.033 23.166-13.373 44.348-33.015 56.673 26.659 9.181 58.968 32.132 58.968 70.621 0 35.31-26.836 70.621-86.51 70.621l-23.305-2.295c-9.71 0-19.421-1.059-24.188-1.059s-10.77 0-18.538 0c-7.768 0-15.89 0-22.422 0s-5.473 0-5.473-1.589c0-1.589 1.236-2.472 5.12-2.472 3.928 0.013 7.842-0.462 11.652-1.412 6.532-1.236 8.121-8.298 9.357-17.655 1.589-13.594 1.589-39.194 1.589-69.032l-0.706-57.732zM348.513 863.514c0 2.648 0.883 3.354 2.119 3.531 5.069 0.888 10.217 1.243 15.36 1.059 8.271 0.97 16.601-1.239 23.305-6.179 9.411-8.797 14.287-21.411 13.241-34.251 0-21.716-12.712-50.317-40.43-50.317-3.579-0.401-7.191-0.401-10.77 0-2.295 0-2.825 1.942-2.825 5.473v80.684zM391.239 889.468c-8.032-5.216-17.436-7.92-27.012-7.768-5.826 0-9.004 0-13.771 0-1.412 0-1.942 0-1.942 2.295v76.447c0 21.363 0.883 26.836 11.299 31.073 7.531 2.921 15.588 4.243 23.658 3.884 11.043 0.484 21.744-3.892 29.283-11.975s11.161-19.062 9.911-30.045c0.572-25.154-11.156-49.006-31.426-63.912v0z"
      />
      <path
        fill="#BC9B6A"
        d="M503.172 886.466c0-55.261 36.546-126.764 133.826-126.764 80.508 0 130.648 46.786 130.648 120.938 1.431 35.849-12.27 70.649-37.757 95.9s-60.412 38.629-96.246 36.866c-34.544 1.942-68.32-10.685-93.118-34.812s-38.347-57.544-37.353-92.128zM722.45 892.646c0-72.386-41.843-117.407-94.985-117.407-37.252 0-79.625 20.657-79.625 101.341 0 67.266 37.252 120.761 99.399 120.761 23.658 0 75.211-11.123 75.211-104.695z"
      />
      <path
        fill="#BC9B6A"
        d="M832.265 858.041c0-49.258 0-58.262 0-68.679s-3.178-16.066-13.948-17.655c-4.13-0.79-8.331-1.145-12.535-1.059-3.531 0-5.473 0-5.473-2.825s2.119-3.001 7.062-3.001c17.655 0 37.606 1.059 44.314 1.059 11.123 0 35.31-1.059 45.727-1.059 20.786-1.459 41.526 3.403 59.498 13.948 13.866 10.52 22.028 26.909 22.069 44.314 0 24.011-10.063 46.080-42.726 72.563 28.778 36.37 51.2 65.148 70.621 85.274 8.982 10.656 21.192 18.094 34.781 21.186 3.018 0.652 6.093 1.007 9.181 1.059 2.472 0 3.884 0 3.884 2.472s-2.295 3.354-6.179 3.354h-30.367c-12.115 1.245-24.338-0.894-35.31-6.179-17.982-12.571-33.087-28.819-44.314-47.669-12.888-17.655-27.895-39.724-32.132-44.668-1.444-1.68-3.624-2.539-5.826-2.295h-28.072c-1.589 0-2.648 0-2.648 2.472v4.59c0 30.19 0 55.79 1.589 69.385 1.059 9.357 3.001 16.419 12.712 17.655 5.264 0.782 10.57 1.254 15.89 1.412 2.472 0 3.884 0 3.884 2.472s-2.295 3.354-6.532 3.354c-18.714 0-42.726-1.059-47.669-1.059s-26.483 1.059-39.371 1.059c-4.237 0-6.532-1.059-6.532-3.354s1.236-2.472 5.12-2.472c3.928 0.013 7.842-0.462 11.652-1.412 6.532-1.236 8.121-8.298 9.534-17.655 1.589-13.594 1.589-39.194 1.589-69.032l0.53-57.556zM870.4 883.641c0 3.531 0 4.767 3.001 5.826 7.977 2.272 16.248 3.343 24.541 3.178 8.067 1.134 16.265-0.82 22.952-5.473 13.884-12.123 21.053-30.209 19.244-48.552 0-45.903-30.543-59.321-49.611-59.321-5.922-0.342-11.863 0.133-17.655 1.412-2.295 0-3.001 1.942-3.001 4.414l0.53 98.516z"
      />
      <path
        fill="#BC9B6A"
        d="M1139.818 924.425c-1.589 0-2.295 0-2.825 2.648l-17.655 46.257c-2.753 6.265-4.425 12.951-4.943 19.774 0 5.826 3.001 10.063 12.888 10.063h4.943c3.884 0 4.767 0 4.767 2.472s-1.942 3.354-5.473 3.354c-10.24 0-24.188-1.059-34.251-1.059-3.531 0-21.363 1.059-38.312 1.059-4.061 0-6.003 0-6.003-3.354s1.236-2.472 3.884-2.472c3.293 0.188 6.594 0.188 9.887 0 15.007-1.942 21.186-12.888 27.542-29.131l81.567-204.094c3.884-9.357 5.826-12.888 9.004-12.888s4.943 2.825 8.121 10.417c7.768 17.655 59.498 149.892 80.331 199.15 12.182 29.131 21.716 33.545 28.425 35.31 4.47 0.941 9.026 1.414 13.594 1.412 2.648 0 4.237 0 4.237 2.472s-2.825 3.354-14.83 3.354c-12.006 0-35.31 0-61.263 0-5.826 0-9.71 0-9.71-2.648s1.236-2.472 4.59-2.825c1.42-0.535 2.543-1.653 3.083-3.071s0.446-3-0.258-4.344l-25.953-68.679c-0.344-1.447-1.696-2.423-3.178-2.295l-72.21-0.883zM1205.848 908.182c1.589 0 1.942 0 1.589-1.942l-29.131-79.978c0-1.236 0-2.825-1.589-2.825s-1.589 1.589-1.942 2.825l-29.837 79.801c0 1.236 0 2.119 1.412 2.119h59.498z"
      />
      <path
        fill="#BC9B6A"
        d="M1463.614 858.041c0-49.258 0-58.262 0-68.679s-3.178-16.066-13.948-17.655c-4.129-0.798-8.331-1.153-12.535-1.059-3.531 0-5.473 0-5.473-2.825s2.119-3.001 7.062-3.001c17.655 0 37.606 1.059 43.961 1.059 15.89 0 28.601-1.059 39.548-1.059 58.615 0 69.032 31.426 69.032 48.552-1.033 23.166-13.373 44.348-33.015 56.673 26.483 9.181 58.968 32.132 58.968 70.621 0 35.31-27.012 70.621-86.51 70.621l-22.775-0.883c-9.71 0-19.421-1.059-24.188-1.059s-10.77 0-17.655 0c-6.886 0-15.713 0-22.246 0s-6.532-1.059-6.532-3.354c0-2.295 1.236-2.472 5.297-2.472 3.926-0.011 7.837-0.485 11.652-1.412 6.356-1.236 7.945-8.298 9.357-17.655 1.589-13.594 1.589-39.194 1.589-69.032l-1.589-57.379zM1502.455 863.514c0 2.648 0.883 3.354 2.295 3.531 5.009 0.89 10.099 1.245 15.183 1.059 8.275 1.024 16.625-1.19 23.305-6.179 9.478-8.752 14.368-21.4 13.241-34.251 0-21.716-12.535-50.317-40.43-50.317-3.52-0.401-7.073-0.401-10.593 0-2.295 0-3.001 1.942-3.001 5.473v80.684zM1545.181 889.468c-7.964-5.221-17.314-7.928-26.836-7.768-5.826 0-9.004 0-13.948 0-1.236 0-1.942 0-1.942 2.295v76.447c0 21.363 0.883 26.836 11.299 31.073 7.539 2.891 15.59 4.213 23.658 3.884 11.061 0.546 21.8-3.811 29.354-11.909s11.154-19.114 9.841-30.11c0.572-25.154-11.156-49.006-31.426-63.912v0z"
      />
      <path
        fill="#BC9B6A"
        d="M1657.114 886.466c0-55.261 36.723-126.764 133.826-126.764 80.684 0 130.825 46.786 130.825 120.938 1.376 35.834-12.342 70.601-37.816 95.84s-60.367 38.635-96.186 36.927c-34.574 1.993-68.397-10.611-93.235-34.744s-38.41-57.58-37.413-92.197v0zM1876.392 892.646c0-72.386-41.666-117.407-94.808-117.407-37.252 0-79.625 20.657-79.625 101.341 0 67.266 37.076 120.761 99.399 120.761 22.599 0 75.034-11.123 75.034-104.695z"
      />
      <path
        fill="#BC9B6A"
        d="M1986.207 858.041c0-49.258 0-58.262 0-68.679s-3.354-16.066-13.948-17.655c-4.19-0.788-8.449-1.143-12.712-1.059-3.531 0-5.473 0-5.473-2.825s2.295-3.001 7.062-3.001c17.655 0 37.606 1.059 44.491 1.059 10.946 0 35.31-1.059 45.55-1.059 20.844-1.484 41.648 3.379 59.674 13.948 13.757 10.592 21.839 26.953 21.892 44.314 0 24.011-9.887 46.080-42.726 72.563 28.954 36.37 51.2 65.148 70.621 85.274 9.265 11.239 22.101 18.965 36.37 21.892 2.959 0.652 5.975 1.007 9.004 1.059 2.648 0 3.884 0 3.884 2.472s-2.295 3.354-6.179 3.354h-32.132c-12.117 1.312-24.359-0.831-35.31-6.179-18.025-12.521-33.14-28.78-44.314-47.669-13.065-17.655-27.895-39.724-32.132-44.668-1.444-1.68-3.624-2.539-5.826-2.295h-28.248c-1.589 0-2.472 0-2.472 2.472v4.59c0 30.19 0 55.79 1.589 69.385 0 9.357 2.825 16.419 12.535 17.655 5.265 0.775 10.57 1.246 15.89 1.412 2.648 0 3.884 0 3.884 2.472s-2.295 3.354-6.356 3.354c-18.891 0-42.902-1.059-47.669-1.059s-26.483 1.059-39.548 1.059c-4.237 0-6.356-1.059-6.356-3.354s1.236-2.472 5.12-2.472c3.926-0.011 7.837-0.485 11.652-1.412 6.532-1.236 8.121-8.298 9.357-17.655 1.589-13.594 1.589-39.194 1.589-69.032l1.236-58.262zM2024.519 883.641c0 3.531 0 4.767 2.825 5.826 8.040 2.261 16.367 3.332 24.717 3.178 8.067 1.134 16.265-0.82 22.952-5.473 13.819-12.158 20.92-30.239 19.068-48.552 0-45.903-30.367-59.321-49.611-59.321-5.922-0.32-11.86 0.155-17.655 1.412-2.295 0-2.825 1.942-2.825 4.414l0.53 98.516z"
      />
      <path
        fill="#BC9B6A"
        d="M2293.937 924.425c-1.766 0-2.295 0-3.001 2.648l-17.655 46.257c-2.631 6.295-4.241 12.971-4.767 19.774 0 5.826 2.825 10.063 12.888 10.063h4.767c3.884 0 4.943 0 4.943 2.472s-1.942 3.354-5.473 3.354c-10.417 0-24.364-1.059-35.31-1.059-3.531 0-21.363 1.059-38.135 1.059-4.237 0-6.179 0-6.179-3.354s1.236-2.472 3.884-2.472c3.352 0.188 6.712 0.188 10.063 0 14.83-1.942 21.010-12.888 27.542-29.131l82.979-204.094c3.884-9.357 5.826-12.888 9.181-12.888s4.767 2.825 7.945 10.417c7.768 17.655 59.674 149.892 80.331 199.15 12.359 29.131 21.716 33.545 28.601 35.31 4.469 0.95 9.026 1.424 13.594 1.412 2.472 0 4.061 0 4.061 2.472s-2.825 3.354-14.83 3.354c-12.006 0-35.31 0-61.263 0-5.826 0-9.71 0-9.71-2.648s1.412-2.472 4.59-2.825c1.452-0.498 2.616-1.601 3.192-3.023s0.506-3.025-0.191-4.392l-25.953-68.679c-0.405-1.405-1.717-2.352-3.178-2.295l-72.916-0.883zM2359.967 908.182c1.589 0 1.942 0 1.589-1.942l-29.131-79.978c0-1.236 0-2.825-1.589-2.825s-1.766 1.589-1.942 2.825l-29.837 79.801c0 1.236 0 2.119 1.236 2.119h59.674z"
      />
      <path
        fill="#BC9B6A"
        d="M2635.741 964.855c0 25.247 4.943 33.721 11.299 35.31 5.707 1.783 11.682 2.56 17.655 2.295 3.531 0 5.473 0 5.473 2.472s-3.001 3.354-7.415 3.354c-21.186 0-34.074-1.059-40.254-1.059-2.825 0-17.655 1.059-35.31 1.059-4.237 0-7.062 0-7.062-3.354s1.942-2.472 5.12-2.472c4.931 0.064 9.851-0.47 14.654-1.589 8.298-2.648 9.71-12.006 10.063-40.254l3.001-191.029c0-6.356 1.059-10.946 4.237-10.946s6.532 4.237 12.006 10.063c5.473 5.826 52.966 57.026 100.281 103.989 22.069 21.892 65.854 68.502 70.621 73.799h1.589l-3.178-145.479c0-19.774-3.354-25.777-11.123-29.131-5.734-1.637-11.703-2.293-17.655-1.942-3.884 0-5.12 0-5.12-2.825s3.531-3.001 8.298-3.001c17.655 0 32.486 1.059 39.194 1.059 3.708 0 15.89-1.059 31.779-1.059 4.237 0 7.415 0 7.415 3.001s-1.942 2.825-5.826 2.825c-3.272-0.136-6.545 0.221-9.71 1.059-9.004 2.472-11.652 9.357-12.006 27.542l-3.531 204.27c0 7.062-1.236 10.063-4.237 10.063s-7.415-3.531-10.946-7.239c-20.48-19.421-61.793-61.793-95.514-95.161s-70.621-75.388-77.153-81.567v0l3.354 135.945z"
      />
      <path
        fill="#BC9B6A"
        d="M2921.048 858.041c0-49.258 0-58.262 0-68.679s-3.354-16.066-13.948-17.655c-4.19-0.788-8.449-1.143-12.712-1.059-3.531 0-5.473 0-5.473-2.825s2.295-3.001 7.062-3.001c17.655 0 37.606 1.059 47.316 1.059s28.248-1.059 39.901-1.059c4.767 0 7.062 0 7.062 3.001s-1.942 2.825-5.12 2.825c-3.148 0.022-6.284 0.377-9.357 1.059-8.828 1.589-11.476 7.062-12.006 17.655s0 19.421 0 68.679v45.374c0 46.963 9.357 66.737 25.247 79.625 11.4 9.216 25.787 13.928 40.43 13.241 16.69 0.249 32.804-6.095 44.844-17.655 17.655-17.655 18.714-46.963 18.714-80.331v-40.254c0-49.258 0-58.262 0-68.679s-3.178-16.066-13.948-17.655c-3.836-0.761-7.742-1.116-11.652-1.059-3.531 0-5.473 0-5.473-2.825s2.295-3.001 6.886-3.001c16.419 0 36.899 1.059 37.252 1.059 3.884 0 24.188-1.059 36.899-1.059 4.414 0 6.709 0 6.709 3.001s-1.942 2.825-5.826 2.825c-3.148 0.022-6.284 0.377-9.357 1.059-8.828 1.589-11.299 7.062-12.006 17.655s0 19.421 0 68.679v35.31c0 35.31-3.531 74.152-30.72 97.457-18.627 16.066-42.692 24.404-67.266 23.305-25.891 1.828-51.528-6.108-71.857-22.246-16.596-14.83-28.954-38.841-28.954-85.804l-2.648-48.022z"
      />
      <path
        fill="#BC9B6A"
        d="M3216.949 858.041c0-49.258 0-58.262 0-68.679s-3.884-16.419-10.946-17.655c-3.799-1.011-7.721-1.487-11.652-1.412-3.178 0-5.12 0-5.12-3.178s2.472-2.648 7.768-2.648c12.182 0 32.662 1.059 42.019 1.059s27.189-1.059 39.548-1.059c4.237 0 6.709 0 6.709 2.648s-1.942 3.178-5.12 3.178c-3.263 0.041-6.515 0.395-9.71 1.059-8.828 1.589-11.299 7.062-12.006 17.655s0 19.421 0 68.679v57.026c0 31.426 0 57.026 1.412 70.621 0.883 8.651 3.178 14.477 12.888 15.713 5.495 0.836 11.039 1.307 16.596 1.412 3.531 0 5.12 0 5.12 2.472s-2.648 3.354-6.179 3.354c-21.363 0-41.666-1.059-50.494-1.059s-27.895 1.059-40.783 1.059c-4.237 0-6.532-1.059-6.532-3.354s1.412-2.472 5.297-2.472c3.926-0.011 7.837-0.485 11.652-1.412 6.356-1.236 8.298-6.709 9.357-16.066 1.589-13.594 1.589-39.194 1.589-70.621l-1.412-56.32z"
      />
    </svg>
  );
}
